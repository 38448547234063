import React, { useEffect } from "react";
import TitleCard from "./title-card";
import store, { addAsynDataTableReducer } from "../../store";
import { connect } from "react-redux";
import getDataTable, { delTableItem } from "../../api/data-table-api";
import DataTableView from "../views/data-table-view";
import {
  FullWidthContainer,
  ConWindowFrame,
  H2Title,
  PLGeneral,
  SpanBGeneral,
  ButtonFuncG,
  ButtonFuncR,
  LinkButtonG,
  SearchTitle,
} from "../../configurations/general-conf";
import DataTablePGN from "../views/data-table-pgn";
import { confirmAlert } from "react-confirm-alert";
import { getOrderTypes } from "../widgets/utilities";

const DataTable = (props) => {
  const changePageSize = (pageSize) => {
    const { nameSpace } = props.conf;
    store.dispatch({
      type: "change_size" + nameSpace,
      pageSize,
    });
    getDataTable(nameSpace);
  };

  const toPage = (pageIndex) => {
    const { nameSpace } = props.conf;
    getDataTable(nameSpace, pageIndex);
  };

  const toEdit = (key, path) => {
    const to = path + key;
    props.history.push(to);
  };

  const toPath = (e, path) => {
    e.preventDefault();
    props.history.push(path);
  };

  const toDel = (key, type, delName) => {
    const { nameSpace, endPoint, tableCfg } = props.conf;
    const delItemName = delName || getOrderTypes(type) + " order";

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConWindowFrame>
            <H2Title xfontSize="20" xmarginBottom="24">
              Delete Confirmation
            </H2Title>
            <PLGeneral xfontSize="14" xlineHeight="18" xmarginBottom="24">
              Are you sure you want to delete this {delItemName}?
            </PLGeneral>
            <ButtonFuncR onClick={onClose} xmarginRight="24">
              No, thanks.
            </ButtonFuncR>
            <ButtonFuncG
              onClick={() => {
                delTableItem(nameSpace, key, tableCfg.mainKey, endPoint, type);
                if (delItemName === "draft") {
                  setTimeout(() => getDataTable("doa"), 500);
                }
                onClose();
              }}
            >
              Yes, please!
            </ButtonFuncG>
          </ConWindowFrame>
        );
      },
    });
  };

  const toTrack = (cn, fc) => {
    // console.log(fc);

    try {
      navigator.clipboard.writeText(cn);
    } catch (err) {
      console.log(err);
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConWindowFrame>
            <H2Title xfontSize="20" xmarginBottom="24">
              Tracking Order
            </H2Title>
            <PLGeneral xfontSize="14" xlineHeight="18" xmarginBottom="24">
              The consignment number <SpanBGeneral>{cn}</SpanBGeneral> has been
              copied to your clipboard. Would you like to open the shipping
              company <SpanBGeneral>{fc.freightCompanyName}</SpanBGeneral>'s
              website to track your order?
            </PLGeneral>
            <LinkButtonG
              target="_blank"
              rel="noreferrer"
              href={fc.trackingUrl}
              xmarginRight="24"
              onClick={() => {
                onClose();
              }}
            >
              Yes, please!
            </LinkButtonG>
            <ButtonFuncR onClick={onClose} xmarginRight="24">
              No, thanks.
            </ButtonFuncR>
          </ConWindowFrame>
        );
      },
    });
  };

  useEffect(() => {
    const { nameSpace, endPoint, loadingDelay } = props.conf;
    nameSpace &&
      !store.asyncReducers[nameSpace] &&
      addAsynDataTableReducer(store, props.conf);

    if (loadingDelay && nameSpace && endPoint) {
      setTimeout(() => getDataTable(nameSpace), loadingDelay);
    } else {
      nameSpace && endPoint && getDataTable(nameSpace);
    }
  }, [props.conf]);

  const {
    title,
    topBtns,
    tableCfg,
    maxPage,
    pageSize: initPageSize,
    pageSizeOpt,
  } = props.conf;
  const { list, currentPage, pageSize, totalRecords } = props.data;
  // list?.length > 0 && console.log(props.data);
  const totalCreated = props.data.limitTotal || totalRecords;
  return (
    <TitleCard
      title={title}
      topBtns={topBtns}
      toPath={toPath}
      contentPadding="25"
    >
      <FullWidthContainer>
        {props.data?.qryStr && (
          <SearchTitle>
            Search results for: <span>{props.data.qryStr}</span>
          </SearchTitle>
        )}
        <DataTableView
          tableCfg={tableCfg}
          list={list}
          toEdit={toEdit}
          toDel={toDel}
          toTrack={toTrack}
        />
      </FullWidthContainer>
      <div>
        {(totalRecords / pageSize > 1 || pageSize !== initPageSize) && (
          <DataTablePGN
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={totalRecords}
            pageSizeOpt={pageSizeOpt}
            initPageSize={initPageSize}
            toPage={toPage}
            maxPage={maxPage}
            changePageSize={changePageSize}
          />
        )}
      </div>
      {tableCfg.btns?.length > 0 && (
        <FullWidthContainer xmarginTop="10">
          {tableCfg.btns?.map((btn, i) => {
            switch (btn.type) {
              case "link":
                let displayBtn = btn.repOnly ? (store.getState()["company"]["repLogin"] ? true : false) : true;
                return displayBtn && (totalCreated <
                  Number(store.getState()["company"][btn.totalLimitKey]) ||
                  !btn.totalLimitKey) ? (
                  <ButtonFuncG
                    key={i}
                    onClick={(e) => {
                      toPath(e, btn.path);
                    }}
                  >
                    {btn.text}
                  </ButtonFuncG>
                ) : null;
              default:
                return <ButtonFuncG key={i}>{btn.text}</ButtonFuncG>;
            }
          })}
        </FullWidthContainer>
      )}
    </TitleCard>
  );
};

// export default DataTable;

const mapStateToProps = (state, ownProps) => {
  const { nameSpace } = ownProps.conf;
  return {
    data: state[nameSpace] || {},
  };
};

export default connect(mapStateToProps)(DataTable);
