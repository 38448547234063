import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TitleCard from "./title-card";
import {
  theme,
  FullWidthContainer,
  TableGeneral,
  ThGeneral,
  TdGeneral,
  SpanBGeneral,
  SpanLGeneral,
  TableDecorRow,
  DivDecorRow,
  FlexButtonGroup,
  ButtonFuncG,
  ButtonFuncR,
  InputGeneral,
  StyledCheckbox,
  H5Title,
  TextareaGeneral,
  PGeneral,
  maxItemQty,
  popupMaxItemQty,
  LoadingAniC,
  ConWindowFrame,
  H2Title,
  PLGeneral,
  SpanMGeneral,
} from "../../configurations/general-conf";
import {
  formatNumberComma,
  getEarliestEdd,
  getDefaultEdd,
  getEarliestCdt,
  getOrderTypes,
  getOrderTypesDisplayNameCap,
  getOrderTypesDisplayNameLow,
} from "./utilities";
import styled from "styled-components";
import store, { addDraftOrder } from "../../store";
import axios from "axios";
import { getSingleOrder } from "../../api/single-item-api";
import moment from "moment";
import PriceTag from "./price-tag";
import { confirmAlert } from "react-confirm-alert";
import SimpleConfirmation from "./simple-confirmation";
import getDataTable from "../../api/data-table-api";
import { getAmtByQty } from "../widgets/utilities";
import CurrentSampleStockVA from "./current-sample-stock-va";

export const ThDraftOrder = styled(ThGeneral)`
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  line-height: ${theme.proportion * 36 * theme.mobileResizeFactor}px;
`;

export const ThFirst = styled(ThDraftOrder)`
  width: 50%;

  @media only screen and (max-width: 640px) {
    width: 42%;
  }
`;

export const TdDraftOrder = styled(TdGeneral)`
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  line-height: ${theme.proportion * 36 * theme.mobileResizeFactor}px;

  & div span {
    text-transform: lowercase;
  }
`;

const ItemRemover = styled(SpanBGeneral)`
  font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  margin-right: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  cursor: pointer;
`;

const FlexOrderDes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
  padding-bottom: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
  border-bottom: 1px solid ${theme.biege};
`;

const FlexOrderNW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${theme.proportion * 32 * theme.mobileResizeFactor}px;
  padding-bottom: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
  border-bottom: 1px solid ${theme.biege};
`;

const FlexOrderNWPromo = styled(FlexOrderNW)`
  padding-top: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
  margin-bottom: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
`;

export const FlexCheckout = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${(props) =>
    props.xpaddingTop
      ? theme.proportion * props.xpaddingTop * theme.mobileResizeFactor + "px"
      : null};
  padding-bottom: ${(props) =>
    props.xpaddingBottom
      ? theme.proportion * props.xpaddingBottom * theme.mobileResizeFactor +
        "px"
      : null};
  margin-left: ${(props) =>
    props.xmarginLeft
      ? theme.proportion * props.xmarginLeft * theme.mobileResizeFactor + "px"
      : null};

  @media only screen and (max-width: 640px) {
    margin-left: ${(props) =>
      props.xmarginLeft
        ? theme.proportion *
            props.xmarginLeft *
            theme.mobileResizeFactor *
            0.8 +
          "px"
        : null};
  }
`;

const FlexTimingDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: ${theme.proportion * 50 * theme.mobileResizeFactor}px;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    padding-top: ${theme.proportion * 1 * theme.mobileResizeFactor}px;
  }
`;

const DecorDiv = styled.div`
  height: ${theme.proportion * 110 * theme.mobileResizeFactor}px;
  border-left: 1px solid ${theme.biege};
`;

export const NumberInput = styled(InputGeneral)`
  width: ${(props) =>
    theme.proportion * props.xwidth * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 22 * theme.mobileResizeFactor}px;

  @media only screen and (max-width: 640px) {
    width: ${theme.proportion * 60 * theme.mobileResizeFactor}px;
  }
`;

const TextInput = styled(InputGeneral)`
  width: ${(props) =>
    theme.proportion * props.xwidth * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 28 * theme.mobileResizeFactor}px;

  @media only screen and (max-width: 640px) {
    width: ${(props) =>
      theme.proportion * props.xwidth * theme.mobileResizeFactor * 0.8}px;
  }
`;

const TextInputPromo = styled(TextInput)`
  font-family: ${theme.lightFont};
  font-style: italic;

  &::placeholder {
    color: ${(props) =>
      props.placeholder.indexOf("Invalid") > -1 ||
      props.placeholder.indexOf("Not applicable") > -1 ||
      props.placeholder.indexOf("Insufficient") > -1
        ? theme.noticeRed
        : theme.charcoal};
  }
`;

export const ButtonFuncGApply = styled(ButtonFuncG)`
  padding: 0;
  width: ${theme.proportion * 70 * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 30 * theme.mobileResizeFactor}px;
  margin-left: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  background-color: ${(props) =>
    props.disabled ? theme.astGreenLight : theme.astGreen};
  border: 0;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? theme.astGreenLight : theme.astGreenHover};
  }
`;

export const SpInsTextarea = styled(TextareaGeneral)`
  width: 100%;
  font-style: italic;
`;

export const OrderForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: ${(props) =>
    props.contentLayout ? props.contentLayout : "column"};
  align-items: ${(props) =>
    props.contentAlign ? props.contentAlign : "center"};
`;

const CouponMsgBlock = styled.div`
  color: ${theme.charcoal};
  font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  font-family: ${theme.romanFont};
  background-color: ${theme.biege};
  padding: ${theme.proportion * 8 * theme.mobileResizeFactor}px 0
    ${theme.proportion * 8 * theme.mobileResizeFactor}px
    ${theme.proportion * 8 * theme.mobileResizeFactor}px;
  margin-bottom: ${theme.proportion * 2 * theme.mobileResizeFactor}px;
  margin-left: ${theme.proportion * 33 * theme.mobileResizeFactor}px;
`;

const DraftOrder = (props) => {
  useEffect(() => {
    !store.getState()["draftOrder"] && addDraftOrder(store);

    const { uuid } = props.match.params;
    uuid &&
      uuid !== store.getState()["draftOrder"]?.["saleOrderUuid"] &&
      getSingleOrder(uuid, props.dyPath);
    props.match.url.indexOf("/sampleorder/createnew/draftorder") > -1 &&
      props.history.push(props.dyPath + "/convert");
    props.match.url.indexOf("/orderonline/allocatedorder/draftorder") > -1 &&
      props.history.push(props.dyPath + "/convert");
  }, [props.match.params, props.dyPath, props.history, props.match.url]);

  const [smode, setSmode] = useState("");
  // const [smode, setSmode] = useState(
  //   props.dyPath === "/sampleorder/createnew" ? "convert_to_order" : ""
  // );
  const [dissub, setDissub] = useState(false);
  const [isBigOrder, setIsBigOrder] = useState("");
  const [promoCode, setPromoCode] = useState({
    code: "",
    msg: "Enter Promo Code",
    input: "",
    discountAmount: 0,
    discountValue: 0,
    discountItems: [],
  });

  const addMoreProducts = (e) => {
    e.preventDefault();
    const to = props.dyPath + "/addproduct";
    props.history.push(to);
  };

  // const verifyPromoCode = async (e) => {
  //   e.preventDefault();
  //   console.log(promoCode);
  //   if (props.data.saleOrderUuid) {
  //     const postItem = { ...props.data };
  //     postItem.status = 1;
  //     const res = await axios.put(
  //       "/saleorders/" + props.data.saleOrderUuid + "/oos/verify/" + promoCode,
  //       postItem
  //     );
  //     console.log(res.data);
  //   }
  // };

  const removeOrderItem = (productUuid) => {
    store.dispatch({
      type: "remove_draft_order_item",
      productUuid,
    });
  };

  const removeSampleItem = (sampleCombinationUuid) => {
    store.dispatch({
      type: "remove_draft_sample_item",
      sampleCombinationUuid,
    });
  };

  const handleNumberChange = (productUuid, type, value) => {
    if (!value || parseFloat(value) === 0) {
      store.dispatch({
        type: "remove_draft_order_item",
        productUuid,
      });
    } else {
      if (type === "qty") {
        value > maxItemQty && popupMaxItemQty();
        store.dispatch({
          type: "change_draft_order_qty",
          productUuid,
          value,
        });
      }
    }
  };

  const handleSampleNumberChange = (sampleCombinationUuid, type, value) => {
    if (!value || parseFloat(value) === 0) {
      store.dispatch({
        type: "remove_draft_sample_item",
        sampleCombinationUuid,
      });
    } else {
      if (type === "qty") {
        store.dispatch({
          type: "change_draft_sample_qty",
          sampleCombinationUuid,
          value,
        });
      }
    }
  };

  const handleMainKeyChange = (key, value) => {
    if (props.data[key] !== value) {
      store.dispatch({
        type: "change_draft_order_mainKey",
        key,
        value,
      });

      if (key === "edd" && value && value < getDefaultEdd()) {
        confirmAlert({
          closeOnClickOutside: false,
          closeOnEscape: false,
          customUI: ({ onClose }) => {
            return (
              <ConWindowFrame>
                <H2Title xfontSize="20" xmarginBottom="24">
                  Courtesy Reminder
                </H2Title>
                <PLGeneral xfontSize="14" xlineHeight="18" xmarginBottom="24">
                  Please note the date you have selected may incur an additional
                  fee as priority dispatch, would you like to proceed?
                </PLGeneral>
                <ButtonFuncR onClick={onClose} xmarginRight="24">
                  Proceed
                </ButtonFuncR>
                <ButtonFuncG
                  onClick={() => {
                    store.dispatch({
                      type: "change_draft_order_mainKey",
                      key,
                      value: null,
                    });
                    onClose();
                  }}
                >
                  Reselect
                </ButtonFuncG>
              </ConWindowFrame>
            );
          },
        });
      }
    }
  };

  const handleKeyPress = (e, key, value) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (value !== data[key]) {
        handleMainKeyChange(key, value);
      }
    }
  };

  const handleSubmit = async (e) => {
    // console.log(smode);
    e.preventDefault();
    // console.log(store.getState()["company"]);

    if (props.data.saleOrderUuid) {
      if (smode === "convert_to_order") {
        props.history.push(props.dyPath + "/convert");
      } else {
        const postItem = { ...props.data };
        let exceedMaxItemQty = false;
        postItem.saleItems?.map((item) => {
          if (item.quantity > maxItemQty) {
            popupMaxItemQty();
            exceedMaxItemQty = true;
          }
          return item;
        });
        if (exceedMaxItemQty) {
          return null;
        }
        const futureOrderDays = 60;
        const daysFromNow = Math.abs(moment().diff(postItem.edd, "days"));

        if (smode === "place_order" || smode === "apply_promo") {
          postItem.deliveryFlag =
            daysFromNow > futureOrderDays && postItem.status !== 9 ? 0 : 1;
          postItem.status =
            props.dyPath === "/sampleorder/createnew"
              ? 102
              : daysFromNow > futureOrderDays && postItem.status !== 9
              ? 10
              : 1;
          postItem.info5 = store.getState()["company"]["oosLogin"];
          if (store.getState()["company"]["status"] === 3) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <SimpleConfirmation
                    title="Account Issue"
                    message="Your account is in Credit Hold status. Please contact AST before placing orders. Thanks."
                    onClose={onClose}
                  />
                );
              },
            });
            return null;
          }
        }
        postItem.info1 = postItem.customerDeliveryType === 2 ? "0" : "3";
        // console.log(postItem);

        const url =
          (postItem.status === 102
            ? "/sampleorders/"
            : postItem.status === 100 || postItem.status === 112
            ? "/sampleorders/sampleitem/"
            : "/saleorders/") +
          props.data.saleOrderUuid +
          ((smode === "place_order" || smode === "apply_promo") &&
          (postItem.status === 1 || postItem.status === 102)
            ? "/oos"
            : "") +
          (smode === "apply_promo" ? (props.dyPath === "/orderonline/allocatedorder" ? "/replace" : "/verify") : "/edit") +
          ((promoCode.input !== "" || promoCode.code !== "") &&
          smode !== "save_draft"
            ? "/" + (promoCode.input || promoCode.code)
            : "");
        // console.log(url);

        setDissub(true);

        if (postItem.status === 102) {
          postItem.deliveryFlag = 0;
          const newSaleItems = [];
          // console.log(postItem);
          postItem.sampleItems.forEach((item) => {
            item.updateFlag !== -1 &&
              item.smapleInfo.products.forEach((product) => {
                const productIndex = newSaleItems.findIndex(
                  (obj) => obj.productUuid === product.productUuid
                );
                if (productIndex >= 0) {
                  newSaleItems[productIndex].quantity += item.quantity;
                  newSaleItems[productIndex].amt = getAmtByQty(
                    newSaleItems[productIndex].quantity,
                    newSaleItems[productIndex].unitSize
                  );
                } else {
                  newSaleItems.push({
                    ...product,
                    quantity: item.quantity,
                    amt: getAmtByQty(item.quantity, item.unitSize),
                    totalValue: 0,
                  });
                }
              });
          });
          // console.log(newSaleItems);
          postItem.status = 100;
          // console.log(postItem);
          const url_sync =
            "/sampleorders/sampleitem/" + props.data.saleOrderUuid + "/edit";
          const res_sync = await axios.put(url_sync, postItem);
          if (res_sync.data.result !== 1) {
            setDissub(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return res_sync.data.msg.indexOf("PO exists") > -1 ||
                  res_sync.data.msg.indexOf("Duplicate") > -1 ? (
                  <SimpleConfirmation
                    title="Duplicate Sample Order PO#"
                    message="All sample orders must have a unique PO#. Please choose another PO# before proceeding."
                    onClose={onClose}
                  />
                ) : (
                  <SimpleConfirmation
                    title="Oops..."
                    message={res_sync.data.msg}
                    onClose={onClose}
                  />
                );
              },
            });
            return;
          } else {
            postItem.status = 102;
            postItem.saleItems = newSaleItems;
          }
        }

        // console.log(postItem);
        const res = await axios.put(url, postItem);
        if (res.data.result === 1) {
          // console.log(res.data);
          const couponResult = res.data?.objs?.[0];
          if (smode === "apply_promo") {
            setPromoCode({
              code: couponResult.couponCode,
              msg: "Code has been added.",
              input: "",
              discountAmount: couponResult.discountAmount,
              discountValue: couponResult.discountValue,
              discountItems: couponResult.saleItems,
            });
            setDissub(false);
            if(props.dyPath === "/orderonline/allocatedorder") {
              getSingleOrder(props.data.saleOrderUuid, props.dyPath);
            }
            return;
          }

          smode === "save_draft" &&
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <SimpleConfirmation
                    title={
                      getOrderTypesDisplayNameCap(postItem.status) + " Updated"
                    }
                    message={
                      "Your " +
                      getOrderTypesDisplayNameLow(postItem.status) +
                      " has been successfully updated."
                    }
                    onClose={onClose}
                  />
                );
              },
            });
          smode === "place_order" &&
            (res.data.objs?.[0]?.status === 1 || postItem.status === 10) &&
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <SimpleConfirmation
                    title="Thank you for your order."
                    message="Your order has been successfully placed. You will receive an email notification when your order is processed. We will be in contact if there are any concerns regarding your order."
                    onClose={onClose}
                  />
                );
              },
            });
          smode === "place_order" &&
            (res.data.objs?.[0]?.status === 12 ||
              res.data.objs?.[0]?.status === 112) &&
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <SimpleConfirmation
                    title="Thank you for your order."
                    message="Unfortunately, the stock item(s) you've ordered are temporarily sold out, your order has been successfully placed as a “Back Order”. Our customer service team will contact you shortly regarding the stock availability ETA. We apologise for any inconvenience this may have caused."
                    onClose={onClose}
                  />
                );
              },
            });
          // window.alert(res.data.msg);
          store.dispatch({
            type: "reset_draft_order",
          });
          if (props.dyPath === "/orderonline") {
            props.history.push("/");
            setTimeout(() => getDataTable("doa"), 500);
          }
          if (props.dyPath === "/orderonline/allocatedorder") {
            props.history.push("/");
            setTimeout(() => getDataTable("doa"), 500);
          }
          if (props.dyPath === "/temporders/createnew") {
            props.history.push("/temporders");
            setTimeout(() => getDataTable("ytho"), 500);
          }
          if (props.dyPath === "/sampleorder/createnew") {
            props.history.push("/sampleorder");
            setTimeout(() => getDataTable("ysmplo"), 500);
          }
        } else {
          if (smode === "apply_promo") {
            const codeNotOnPallet =
              res.data.msg === "no item use coupon code" && isBigOrder;
            const popMsg =
              "This code is not valid for any orders over " +
              isBigOrder.toLowerCase() +
              ".";
            codeNotOnPallet &&
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <SimpleConfirmation
                      title="Code Not Applicable"
                      message={popMsg}
                      onClose={onClose}
                    />
                  );
                },
              });
            const msg = codeNotOnPallet
              ? "Not applicable over " + isBigOrder.toLowerCase() + "."
              : res.data.msg === "This is a back order."
              ? "Insufficient stock."
              : "Invalid code. Try again.";
            setPromoCode({
              ...promoCode,
              msg: msg,
              input: "",
            });
            setDissub(false);
            return;
          }

          confirmAlert({
            customUI: ({ onClose }) => {
              return res.data.msg.indexOf("PO exists") > -1 ||
                res.data.msg.indexOf("Duplicate") > -1 ? (
                <SimpleConfirmation
                  title="Duplicate Customer's PO# / Order Name"
                  message="This PO# / Order name has already been used. Please use a different PO# / Order name for this order."
                  onClose={onClose}
                />
              ) : (
                <SimpleConfirmation
                  title="Oops..."
                  message={res.data.msg}
                  onClose={onClose}
                />
              );
            },
          });
          // window.alert(res.data.msg);
          // console.log(res.data.msg);
          setDissub(false);
        }
      }
    } else {
      const postItem = {
        ...props.data,
        customerUuid: store.getState()["company"]["customerUuid"],
        status: 0,
      };

      // console.log(postItem);

      setDissub(true);
      const res = await axios.post("/saleorders/new", postItem);

      // console.log(res.data);

      if (res.data.result === 1) {
        if (smode === "save_draft") {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <SimpleConfirmation
                  title="Save Draft"
                  message="Your draft has been successfully saved to your dashboard. Please note this is a draft only, you must convert to order if you wish to proceed."
                  onClose={onClose}
                />
              );
            },
          });

          store.dispatch({
            type: "reset_draft_order",
          });

          setTimeout(() => getDataTable("doa"), 500);
          props.history.push("/");
        } else {
          if (smode === "convert_to_order") {
            // console.log(res.data?.objs[0]);
            res.data?.objs[0]?.saleItems?.length > 0 &&
              store.dispatch({
                type: "inject_saleItemUuid",
                saleItems: res.data?.objs[0]?.saleItems,
              });
            handleMainKeyChange(
              "saleOrderUuid",
              res.data?.objs[0].saleOrderUuid
            );
            handleMainKeyChange(
              "customerUuid",
              store.getState()["company"]["customerUuid"]
            );
            confirmAlert({
              closeOnClickOutside: false,
              closeOnEscape: false,
              customUI: ({ onClose }) => {
                return (
                  <SimpleConfirmation
                    title="Convert to Order"
                    message="A draft of your order has been automatically saved, please complete all the required fields to proceed with your order."
                    onClose={onClose}
                  />
                );
              },
            });
            setTimeout(() => getDataTable("doa"), 500);
            props.history.push("/orderonline/convert");
          }

          if (smode === "temp_hold") {
            postItem.saleItems = res.data?.objs[0]?.saleItems;
            postItem.saleOrderUuid = res.data?.objs[0].saleOrderUuid;
            postItem.status = 9;
            postItem.info5 = store.getState()["company"]["oosLogin"];
            // console.log(postItem);

            const url_th =
              "/saleorders/" + postItem.saleOrderUuid + "/oos/edit";
            const res_th = await axios.put(url_th, postItem);

            if (res_th.data.result === 1) {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <SimpleConfirmation
                      title="Confirm Temporary Hold"
                      message="Thank you for your order. Your order is now on a Temporary Hold. Your order will remain as Temporary Hold for up to 48 hours. If you have not confirmed this order after the 48 hour period, this order will be deleted."
                      onClose={onClose}
                    />
                  );
                },
              });
              store.dispatch({
                type: "reset_draft_order",
              });
              props.history.push("/temporders");
            } else {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <SimpleConfirmation
                      title="Oops..."
                      message={res_th.data.msg}
                      onClose={onClose}
                    />
                  );
                },
              });
              setDissub(false);
              setTimeout(() => getDataTable("doa"), 500);
            }
          }
        }
      } else {
        // window.alert(res.data.msg);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <SimpleConfirmation
                title="Oops..."
                message={res.data.msg}
                onClose={onClose}
              />
            );
          },
        });
        setDissub(false);
      }
    }
  };

  const { data, mode, dyPath } = props;
  // let { mode } = props;
  // smode === "convert_to_order" && (mode = "checkout");
  // console.log(smode);
  // console.log(data);
  const status = data?.status;
  // console.log(status);
  const cardTitle =
    mode === "checkout"
      ? (status === 1 || status === 11 ? "Order Revision" : "Checkout")
      : getOrderTypes(data?.status || 0) +
        (status === 12 || status === 10 ? " Summary" : " Order Summary");
  let totalCost = 0;
  let totalWeight = 0;

  // console.log(store.getState()["company"]);
  if (data?.customerDeliveryType === 0) {
    if (
      store.getState()["company"]?.shipToAddress &&
      dyPath !== "/sampleorder/createnew"
    ) {
      data.shipToAddress = store.getState()["company"]?.shipToAddress;
      data.shipToCity = store.getState()["company"]?.shipToCity;
      data.shipToState = store.getState()["company"]?.shipToState;
      data.shipToPostcode = store.getState()["company"]?.shipToPostcode;
      data.shipToReceiver = store.getState()["company"]?.shipToReceiver || " ";
    } else {
      data.shipToAddress = store.getState()["company"]?.address;
      data.shipToCity = store.getState()["company"]?.city;
      data.shipToState = store.getState()["company"]?.state;
      data.shipToPostcode = store.getState()["company"]?.postcode;
      data.shipToReceiver = " ";
    }
  }

  if (data?.customerDeliveryType === 2) {
    data.shipToAddress = store.getState()["company"]?.pickupAddress?.address;
    data.shipToCity = store.getState()["company"]?.pickupAddress?.city;
    data.shipToState = store.getState()["company"]?.pickupAddress?.state;
    data.shipToPostcode = store.getState()["company"]?.pickupAddress?.postcode;
    data.shipToReceiver =
      store.getState()["company"]?.pickupAddress?.pickupAddressName;
  }

  if (data) {
    let priorityCharge = data.orderInfo;
    // console.log(priorityCharge);
    const eddDate = data?.edd ? moment(data?.edd).format("YYYY-MM-DD") : null;
    // console.log(eddDate);
    priorityCharge =
      eddDate && eddDate <= moment().format("YYYY-MM-DD") ? "1" : "0";
    const today = new Date().getDay();
    const tomorrow = moment()
      .add(today >= 5 ? 8 - today : 1, "days")
      .format("YYYY-MM-DD");
    // const cdtHour = data?.customerDeliveryTime?.split(":")[0];
    const hoursNow = moment().get("hour");
    if (
      eddDate === tomorrow &&
      // cdtHour > "08" &&
      // cdtHour < "13" &&
      (hoursNow >= 13 || today === 6 || today === 0)
    ) {
      priorityCharge = "1";
    }
    if (priorityCharge !== data.orderInfo) {
      data.orderInfo = priorityCharge;
    }
  }

  let handlingCost = data?.orderInfo === "1" ? 40 : 0;

  let isRender = true;
  const { uuid } = props.match.params;
  if (mode === "edit" && data?.saleOrderUuid !== uuid) {
    isRender = false;
  }

  // let isSmallOrder = true;

  // console.log(promoCode);

  return (
    <TitleCard title={cardTitle} contentPadding="25">
      {isRender && (
        <OrderForm onSubmit={handleSubmit}>
          <FullWidthContainer>
            {data?.saleItems?.length > 0 ? (
              <TableGeneral>
                <thead>
                  <tr>
                    <ThFirst>Product</ThFirst>
                    <ThDraftOrder>Quantity</ThDraftOrder>
                    <ThDraftOrder>Unit</ThDraftOrder>
                    <ThDraftOrder>Price</ThDraftOrder>
                  </tr>
                </thead>
                <tbody>
                  <TableDecorRow xheight="5" />
                  <TableDecorRow xheight="28" />
                  {data.saleItems.map((item, i) => {
                    // isSmallOrder = item.quantity < item.productInfo?.bulkQty;
                    item.productInfo?.price1 > item.productInfo?.price2 &&
                      item.quantity >= item.productInfo?.bulkQty &&
                      !isBigOrder &&
                      setIsBigOrder(
                        item.productInfo?.bulkQty -
                          1 +
                          " " +
                          item.productInfo?.unitName?.split("|")[1] || "units"
                      );
                    const discountAmount =
                      item.productUuid ===
                      promoCode.discountItems[i]?.productUuid
                        ? promoCode.discountItems[i]?.discountAmount
                        : item.discountAmount;
                    const unitPrice =
                      item.quantity >= item.productInfo?.bulkQty &&
                      !discountAmount
                        ? item.productInfo?.price2
                        : item.productInfo?.price1;
                    // console.log(unitPrice);
                    item.unitPrice = unitPrice;
                    item.totalValue = item.quantity * unitPrice;
                    item.weight = item.quantity * item.productInfo?.unitWeight;
                    totalCost +=
                      (item.updateFlag !== -1 ? item.quantity : 0) * unitPrice;
                    totalWeight +=
                      (item.updateFlag !== -1 ? item.quantity : 0) *
                      item.productInfo?.unitWeight;
                    // console.log(promoCode.discountItems[i]);
                    return (
                      item.updateFlag !== -1 && (
                        <tr key={item.productUuid}>
                          <TdDraftOrder>
                            {status !== 9 && status !== 1 && status !== 11 && promoCode.code === "" && (
                              <ItemRemover
                                title="Remove"
                                onClick={() =>
                                  removeOrderItem(item.productUuid)
                                }
                              >
                                X
                              </ItemRemover>
                            )}
                            {item.productInfo?.productName ||
                              item.productInfo?.productColor}
                            {(status === 9 || status === 1 || status === 11) && (
                              <SpanLGeneral
                                xmarginLeft="14"
                                title="Allocated Batch Number"
                              >
                                {item.barcodes}
                              </SpanLGeneral>
                            )}
                          </TdDraftOrder>
                          <TdDraftOrder>
                            {item.amt} {item.productInfo?.unitSymbol || "unit"}
                          </TdDraftOrder>
                          <TdDraftOrder>
                            <NumberInput
                              disabled={
                                status === 9 || status === 1 || status === 11 || promoCode.code !== ""
                                  ? true
                                  : false
                              }
                              type="number"
                              step="1"
                              min="0"
                              id={`iQty_${item["productUuid"]}`}
                              name={`iQty_${item["productUuid"]}`}
                              onBlur={(e) =>
                                handleNumberChange(
                                  item["productUuid"],
                                  "qty",
                                  e.target.value
                                )
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  if (
                                    Number(e.target.value) === item.quantity
                                  ) {
                                    e.preventDefault();
                                  } else {
                                    handleNumberChange(
                                      item["productUuid"],
                                      "qty",
                                      e.target.value
                                    );
                                  }
                                }
                              }}
                              key={`iQty_${item["productUuid"]}_${item.quantity}`}
                              defaultValue={item.quantity}
                              xwidth="70"
                            />{" "}
                            {item.productInfo?.unitName?.split("|")[1] ||
                              "Quantity"}
                          </TdDraftOrder>
                          <TdDraftOrder>
                            <PriceTag
                              unitSymbol={
                                item.productInfo?.unitSymbol?.split("|")[0]
                              }
                              unitNanme={
                                item.productInfo?.unitName?.split("|")[0]
                              }
                              unitSize={item.productInfo?.unitSize}
                              price={item.productInfo?.price1}
                              priceBulk={item.productInfo?.price2}
                              bulkLimiter={item.productInfo?.bulkQty}
                              currentQty={item.quantity}
                              discountAmount={discountAmount}
                            />
                          </TdDraftOrder>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </TableGeneral>
            ) : (
              dyPath === "/orderonline" && (
                <SpanBGeneral xfontSize="14">
                  No product ordered yet.
                </SpanBGeneral>
              )
            )}

            {data?.sampleItems?.length > 0 ? (
              <TableGeneral>
                <thead>
                  <tr>
                    <ThDraftOrder>Combination</ThDraftOrder>
                    <ThDraftOrder>Quantity</ThDraftOrder>
                    <ThDraftOrder>Stock Check</ThDraftOrder>
                  </tr>
                </thead>
                <tbody>
                  <TableDecorRow xheight="5" />
                  <TableDecorRow xheight="28" />
                  {data.sampleItems.map((item) => {
                    return (
                      item.updateFlag !== -1 && (
                        <tr key={item.sampleCombinationUuid}>
                          <TdDraftOrder>
                            <ItemRemover
                              title="Remove"
                              onClick={() =>
                                removeSampleItem(item.sampleCombinationUuid)
                              }
                            >
                              X
                            </ItemRemover>
                            {item.smapleInfo?.combinationName}
                          </TdDraftOrder>
                          <TdDraftOrder>
                            <NumberInput
                              type="number"
                              step="1"
                              min="0"
                              id={`iQty_${item["sampleCombinationUuid"]}`}
                              name={`iQty_${item["sampleCombinationUuid"]}`}
                              onBlur={(e) =>
                                handleSampleNumberChange(
                                  item["sampleCombinationUuid"],
                                  "qty",
                                  e.target.value
                                )
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  if (
                                    Number(e.target.value) === item.quantity
                                  ) {
                                    e.preventDefault();
                                  } else {
                                    handleSampleNumberChange(
                                      item["sampleCombinationUuid"],
                                      "qty",
                                      e.target.value
                                    );
                                  }
                                }
                              }}
                              key={`iQty_${item["sampleCombinationUuid"]}_${item.quantity}`}
                              defaultValue={item.quantity}
                              xwidth="70"
                            />
                          </TdDraftOrder>
                          <TdDraftOrder>
                            <CurrentSampleStockVA data={item} />
                          </TdDraftOrder>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </TableGeneral>
            ) : (
              dyPath === "/sampleorder/createnew" && (
                <SpanBGeneral xfontSize="14">
                  No sample ordered yet.
                </SpanBGeneral>
              )
            )}
            <FlexOrderDes>
              <SpanLGeneral xfontSize="12">
                If any of the details in this order are not appearing correctly
                as discussed
                <br /> by your Sales Rep. Please contact your Sales Rep, and
                reference the
                <br />
                order number, before completing this order
              </SpanLGeneral>
              {window.innerWidth > 640 &&
                dyPath !== "/sampleorder/createnew" && (
                  <SpanBGeneral xfontSize="14">
                    TOTAL ESTIMATED PRODUCT COST ${" "}
                    {(promoCode.discountValue > 0 || data.discountValue > 0) && (
                      <SpanBGeneral
                        xcolor="noticeRed"
                        xmarginRight="5"
                        xfontSize="14"
                      >
                        <strike>{formatNumberComma(totalCost, 2)} +GST</strike>
                      </SpanBGeneral>
                    )}
                    <SpanBGeneral xfontSize="18">
                      {formatNumberComma(
                        totalCost - (promoCode.discountValue !== 0 ? promoCode.discountValue : data.discountValue || 0),
                        2
                      )}{" "}
                      +GST
                    </SpanBGeneral>
                  </SpanBGeneral>
                )}
            </FlexOrderDes>
            <FlexOrderNW>
              <div>
                <SpanBGeneral xfontSize="18" xmarginRight="10">
                  Your PO# / Order Name
                </SpanBGeneral>
                <TextInput
                  type="text"
                  id="info2"
                  name="info2"
                  defaultValue={data?.info2}
                  disabled={status === 1 || status === 11}
                  xwidth="290"
                  maxLength="50"
                  onBlur={(e) => {
                    handleMainKeyChange(
                      "info2",
                      e.target.value.replace(/'/g, "`")
                    );
                  }}
                  required
                  onKeyPress={(e) => {
                    handleKeyPress(
                      e,
                      "info2",
                      e.target.value.replace(/'/g, "`")
                    );
                  }}
                />
              </div>
              {dyPath !== "/sampleorder/createnew" && (
                <SpanBGeneral xfontSize="14">
                  TOTAL WEIGHT OF ORDER (excl. pallet){" "}
                  <SpanBGeneral xcolor="noticeRed">
                    {formatNumberComma(totalWeight, 0)}kg
                  </SpanBGeneral>
                </SpanBGeneral>
              )}
            </FlexOrderNW>
            <DivDecorRow xheight="5" />
            {mode === "checkout" && (
              <>
                <FlexCheckout xpaddingTop="20">
                  <SpanBGeneral xfontSize="18" xmarginRight="20">
                    Delivery
                  </SpanBGeneral>
                  {data?.customerDeliveryType === 2 && (
                    <SpanBGeneral xcolor="noticeRed" xfontSize="14">
                      DAILY ORDER CUT-OFF TIME IS 11AM FOR SAME DAY PICKUP
                    </SpanBGeneral>
                  )}
                </FlexCheckout>
                <FlexCheckout xpaddingTop="30">
                  <FlexCheckout>
                    <SpanBGeneral xfontSize="14" xmarginRight="10">
                      Pickup
                    </SpanBGeneral>
                    <StyledCheckbox>
                      <input
                        type="checkbox"
                        id="pickup"
                        name="pickup"
                        key={`pickup_${data?.customerDeliveryType}`}
                        defaultChecked={data?.customerDeliveryType === 2}
                        disabled={data?.customerDeliveryType === 2}
                        onChange={() =>
                          handleMainKeyChange("customerDeliveryType", 2)
                        }
                      />
                      <span></span>
                    </StyledCheckbox>
                  </FlexCheckout>
                  <FlexCheckout xmarginLeft="60">
                    <SpanBGeneral xfontSize="14" xmarginRight="10">
                      Delivery to Store
                    </SpanBGeneral>
                    <StyledCheckbox>
                      <input
                        type="checkbox"
                        id="dtstore"
                        name="dtstore"
                        key={`dtstore_${data?.customerDeliveryType}`}
                        defaultChecked={data?.customerDeliveryType === 0}
                        disabled={data?.customerDeliveryType === 0}
                        onChange={() =>
                          handleMainKeyChange("customerDeliveryType", 0)
                        }
                      />
                      <span></span>
                    </StyledCheckbox>
                  </FlexCheckout>
                  <FlexCheckout xmarginLeft="60">
                    <SpanBGeneral xfontSize="14" xmarginRight="10">
                      {dyPath !== "/sampleorder/createnew"
                        ? "Delivery to Site"
                        : "Alternative Address"}
                    </SpanBGeneral>
                    <StyledCheckbox>
                      <input
                        type="checkbox"
                        id="dtsite"
                        name="dtsite"
                        key={`dtsite_${data?.customerDeliveryType}`}
                        defaultChecked={data?.customerDeliveryType === 1}
                        disabled={data?.customerDeliveryType === 1}
                        onChange={() =>
                          handleMainKeyChange("customerDeliveryType", 1)
                        }
                      />
                      <span></span>
                    </StyledCheckbox>
                  </FlexCheckout>
                </FlexCheckout>

                <H5Title xfontSize="12" xmarginTop="20">
                  {data?.customerDeliveryType === 2 ? "Pickup" : "Delivery"}{" "}
                  Address
                </H5Title>
                <FlexCheckout xpaddingTop="20">
                  {data?.customerDeliveryType === 2 && (
                    <SpanLGeneral xfontSize="12">
                      {store.getState()["company"]?.pickupAddress.address}
                      <br />
                      {store.getState()["company"]?.pickupAddress.city}{" "}
                      {store.getState()["company"]?.pickupAddress.state}{" "}
                      {store.getState()["company"]?.pickupAddress.postcode}
                    </SpanLGeneral>
                  )}
                  {data?.customerDeliveryType === 0 &&
                    (store.getState()["company"]?.shipToAddress &&
                    dyPath !== "/sampleorder/createnew" ? (
                      <SpanLGeneral xfontSize="12">
                        {store.getState()["company"]?.shipToAddress}
                        <br />
                        {store.getState()["company"]?.shipToCity}{" "}
                        {store.getState()["company"]?.shipToState}{" "}
                        {store.getState()["company"]?.shipToPostcode}
                      </SpanLGeneral>
                    ) : (
                      <SpanLGeneral xfontSize="12">
                        {store.getState()["company"]?.address}
                        <br />
                        {store.getState()["company"]?.city}{" "}
                        {store.getState()["company"]?.state}{" "}
                        {store.getState()["company"]?.postcode}
                      </SpanLGeneral>
                    ))}
                  {(data?.customerDeliveryType === 2 ||
                    data?.customerDeliveryType === 0) && (
                    <SpanLGeneral
                      xfontSize="12"
                      xmarginLeft={window.innerWidth > 640 ? 205 : 50}
                    >
                      If these details are incorrect, please contact
                      <br />
                      our administration team who can update your
                      <br />
                      details for you. Call{" "}
                      <SpanBGeneral>1300 901 355</SpanBGeneral>
                    </SpanLGeneral>
                  )}
                  {data?.customerDeliveryType === 1 && (
                    <SpanLGeneral xfontSize="12" xlineHeight="30">
                      Site Contact:{" "}
                      <TextInput
                        type="text"
                        id="contactName"
                        name="contactName"
                        defaultValue={data?.contactName}
                        xwidth="250"
                        maxLength="50"
                        onBlur={(e) => {
                          handleMainKeyChange("contactName", e.target.value);
                        }}
                        required
                        onKeyPress={(e) => {
                          handleKeyPress(e, "contactName", e.target.value);
                        }}
                      />
                      <br />
                      Site Contact Phone:{" "}
                      <TextInput
                        type="text"
                        id="customerDeliveryPhone"
                        name="customerDeliveryPhone"
                        defaultValue={data?.customerDeliveryPhone}
                        xwidth="214"
                        maxLength="25"
                        onBlur={(e) => {
                          handleMainKeyChange(
                            "customerDeliveryPhone",
                            e.target.value
                          );
                        }}
                        required
                        onKeyPress={(e) => {
                          handleKeyPress(
                            e,
                            "customerDeliveryPhone",
                            e.target.value
                          );
                        }}
                      />
                      <br />
                      Street Address:{" "}
                      <TextInput
                        type="text"
                        id="customerDeliveryAddress"
                        name="customerDeliveryAddress"
                        defaultValue={data?.customerDeliveryAddress}
                        xwidth="237"
                        maxLength="200"
                        onBlur={(e) => {
                          handleMainKeyChange(
                            "customerDeliveryAddress",
                            e.target.value
                          );
                        }}
                        required
                        onKeyPress={(e) => {
                          handleKeyPress(
                            e,
                            "customerDeliveryAddress",
                            e.target.value
                          );
                        }}
                      />
                      <br />
                      Suburb:{" "}
                      <TextInput
                        type="text"
                        id="customerDeliveryCity"
                        name="customerDeliveryCity"
                        defaultValue={data?.customerDeliveryCity}
                        xwidth="276"
                        maxLength="50"
                        onBlur={(e) => {
                          handleMainKeyChange(
                            "customerDeliveryCity",
                            e.target.value
                          );
                        }}
                        required
                        onKeyPress={(e) => {
                          handleKeyPress(
                            e,
                            "customerDeliveryCity",
                            e.target.value
                          );
                        }}
                      />
                    </SpanLGeneral>
                  )}
                  {data?.customerDeliveryType === 1 && (
                    <SpanLGeneral
                      xfontSize="12"
                      xmarginLeft="45"
                      xlineHeight="30"
                    >
                      <FlexCheckout>
                        <SpanLGeneral xfontSize="14" xmarginRight="45">
                          Forklift available:
                        </SpanLGeneral>
                        <StyledCheckbox>
                          <input
                            type="checkbox"
                            id="forklift"
                            name="forklift"
                            key={`forklift_${data?.customerDeliveryForkliftReqired}`}
                            defaultChecked={
                              data?.customerDeliveryForkliftReqired === 1
                            }
                            onChange={(e) => {
                              handleMainKeyChange(
                                "customerDeliveryHandReqired",
                                0
                              );
                              handleMainKeyChange(
                                "customerDeliveryTailgateReqired",
                                0
                              );
                              e.target.checked
                                ? handleMainKeyChange(
                                    "customerDeliveryForkliftReqired",
                                    1
                                  )
                                : handleMainKeyChange(
                                    "customerDeliveryForkliftReqired",
                                    0
                                  );
                            }}
                          />
                          <span></span>
                        </StyledCheckbox>
                      </FlexCheckout>
                      <FlexCheckout>
                        <SpanLGeneral xfontSize="14" xmarginRight="10">
                          Hand unload required:
                        </SpanLGeneral>
                        <StyledCheckbox>
                          <input
                            type="checkbox"
                            id="handunload"
                            name="handunload"
                            key={`handunload_${data?.customerDeliveryHandReqired}`}
                            defaultChecked={
                              data?.customerDeliveryHandReqired === 1
                            }
                            onChange={(e) => {
                              handleMainKeyChange(
                                "customerDeliveryForkliftReqired",
                                0
                              );
                              handleMainKeyChange(
                                "customerDeliveryTailgateReqired",
                                0
                              );
                              e.target.checked
                                ? handleMainKeyChange(
                                    "customerDeliveryHandReqired",
                                    1
                                  )
                                : handleMainKeyChange(
                                    "customerDeliveryHandReqired",
                                    0
                                  );
                            }}
                          />
                          <span></span>
                        </StyledCheckbox>
                      </FlexCheckout>
                      <FlexCheckout>
                        <SpanLGeneral xfontSize="14" xmarginRight="42">
                          Tailgate required:
                        </SpanLGeneral>
                        <StyledCheckbox>
                          <input
                            type="checkbox"
                            id="tailgate"
                            name="tailgate"
                            key={`tailgate_${data?.customerDeliveryTailgateReqired}`}
                            defaultChecked={
                              data?.customerDeliveryTailgateReqired === 1
                            }
                            onChange={(e) => {
                              handleMainKeyChange(
                                "customerDeliveryForkliftReqired",
                                0
                              );
                              handleMainKeyChange(
                                "customerDeliveryHandReqired",
                                0
                              );
                              e.target.checked
                                ? handleMainKeyChange(
                                    "customerDeliveryTailgateReqired",
                                    1
                                  )
                                : handleMainKeyChange(
                                    "customerDeliveryTailgateReqired",
                                    0
                                  );
                            }}
                          />
                          <span></span>
                        </StyledCheckbox>
                      </FlexCheckout>
                      State:{" "}
                      <TextInput
                        type="text"
                        id="customerDeliveryState"
                        name="customerDeliveryState"
                        defaultValue={data?.customerDeliveryState}
                        xwidth={window.innerWidth > 640 ? 137 : 171}
                        maxLength="10"
                        onBlur={(e) => {
                          handleMainKeyChange(
                            "customerDeliveryState",
                            e.target.value
                          );
                        }}
                        required
                        onKeyPress={(e) => {
                          handleKeyPress(
                            e,
                            "customerDeliveryState",
                            e.target.value
                          );
                        }}
                      />
                      <SpanLGeneral
                        xmarginLeft={window.innerWidth > 640 ? 45 : 0}
                      >
                        Postcode:{" "}
                      </SpanLGeneral>
                      <TextInput
                        type="number"
                        id="customerDeliveryPostcode"
                        name="customerDeliveryPostcode"
                        defaultValue={data?.customerDeliveryPostcode}
                        xwidth={window.innerWidth > 640 ? 137 : 143}
                        maxLength="4"
                        onBlur={(e) => {
                          handleMainKeyChange(
                            "customerDeliveryPostcode",
                            e.target.value
                          );
                        }}
                        required
                        onKeyPress={(e) => {
                          handleKeyPress(
                            e,
                            "customerDeliveryPostcode",
                            e.target.value
                          );
                        }}
                      />
                    </SpanLGeneral>
                  )}
                </FlexCheckout>
                <FlexTimingDetails>
                  <div>
                    <H5Title xfontSize="12" xmarginTop="20" xmarginBottom="10">
                      {data?.customerDeliveryType === 2 ? "Pickup" : "Dispatch"}{" "}
                      Date{" "}
                      {data?.customerDeliveryType === 2 ? (
                        ""
                      ) : (
                        <SpanBGeneral
                          xcolor="lightBlue"
                          title="Courtesy Reminder: This is the dispatch date for your order leaving from our warehouse. Please provide a reasonable time frame for your order to be delivered on time."
                        >
                          (?)
                        </SpanBGeneral>
                      )}
                    </H5Title>
                    <TextInput
                      disabled={promoCode.code !== ""}
                      type="date"
                      id="edd"
                      name="edd"
                      xwidth="250"
                      min={getEarliestEdd(data?.customerDeliveryType)}
                      key={`edd_${data?.edd}`}
                      defaultValue={
                        data?.edd
                          ? moment(data?.edd).format("YYYY-MM-DD")
                          : null
                      }
                      onMouseOver={() => {
                        !data?.edd &&
                          handleMainKeyChange("edd", getDefaultEdd());
                      }}
                      onBlur={(e) => {
                        const day = new Date(e.target.value).getUTCDay();
                        if (day === 6 || day === 0) {
                          e.target.setCustomValidity(
                            "Please pick any day but weekends."
                          );
                        } else {
                          e.target.setCustomValidity("");
                          handleMainKeyChange("edd", e.target.value);
                        }
                      }}
                      onKeyPress={(e) => {
                        const day = new Date(e.target.value).getUTCDay();
                        if (day === 6 || day === 0) {
                          e.target.setCustomValidity(
                            "Please pick any day but weekends."
                          );
                        } else {
                          e.target.setCustomValidity("");
                          handleKeyPress(e, "edd", e.target.value);
                        }
                      }}
                      required
                    />
                    {moment().format("YYYY-MM-DD") >=
                      moment().format("2023-12-21") &&
                      moment().format("YYYY-MM-DD") <=
                        moment().format("2024-01-07") && (
                        <PLGeneral
                          xfontSize="12"
                          xmarginTop="10"
                          xmarginBottom="20"
                        >
                          Please note that Australian Select Timbers Christmas
                          <br />
                          Closure is: 21st December 2023 - 7th January 2024,{" "}
                          <br />
                          no orders will be processed during this time.
                        </PLGeneral>
                      )}
                  </div>
                  {window.innerWidth > 640 && <DecorDiv />}
                  <div>
                    <H5Title xfontSize="12" xmarginTop="20" xmarginBottom="10">
                      {data?.customerDeliveryType === 2 ? "Pickup" : "Delivery"}{" "}
                      Time
                    </H5Title>
                    <datalist id="cdtlist">
                      <option value="07:00" />
                      <option value="08:00" />
                      <option value="09:00" />
                      <option value="10:00" />
                      <option value="11:00" />
                      <option value="12:00" />
                      <option value="13:00" />
                      <option value="14:00" />
                      <option value="15:00" />
                    </datalist>
                    {data?.customerDeliveryType === 2 ? (
                      <TextInput
                        disabled={promoCode.code !== ""}
                        list="cdtlist"
                        type="time"
                        id="cdt"
                        name="cdt"
                        xwidth={window.innerWidth > 640 ? 140 : 250}
                        min={getEarliestCdt(data?.edd)}
                        max="15:00"
                        key={`cdt_${data?.customerDeliveryTime}`}
                        defaultValue={data?.customerDeliveryTime || null}
                        onBlur={(e) => {
                          handleMainKeyChange(
                            "customerDeliveryTime",
                            e.target.value
                          );
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(
                            e,
                            "customerDeliveryTime",
                            e.target.value
                          );
                        }}
                        required
                      />
                    ) : (
                      <H5Title xfontSize="12" xmarginTop="20">
                        9am - 5pm
                      </H5Title>
                    )}
                  </div>
                  {handlingCost > 0 ? (
                    <>
                      {window.innerWidth > 640 && <DecorDiv />}
                      <div>
                        <H5Title xfontSize="12" xmarginTop="20">
                          Priority
                        </H5Title>
                        <H5Title
                          xfontSize="12"
                          xmarginTop="20"
                          xmarginBottom="20"
                          add
                          promo
                        >
                          Priority Order (+ $40.00)
                        </H5Title>
                        <SpanLGeneral xfontSize="12">
                          **Extra $40.00 Charge per order is applied to any
                          priority
                          <br />
                          service (next day dispatch service). Pickups available
                          <br />
                          time for priority orders is 8am onwards
                        </SpanLGeneral>
                      </div>
                    </>
                  ) : (
                    <SpanBGeneral
                      xwidth={window.innerWidth > 640 ? 350 : 10}
                    ></SpanBGeneral>
                  )}
                </FlexTimingDetails>
                {dyPath.indexOf("/orderonline") > -1 &&
                  mode === "checkout" &&
                  // false &&
                  // isSmallOrder &&
                  // !store.getState()["company"]?.repLogin &&
                    <FlexOrderNWPromo>
                      <div>
                        <SpanBGeneral xfontSize="18" xmarginRight="10">
                          {data.couponCode ? "Replace" : "Add"} Promo Code
                        </SpanBGeneral>
                        <TextInputPromo
                          type="text"
                          id="promo_code"
                          name="promo_code"
                          xwidth="210"
                          maxLength="20"
                          placeholder={promoCode.msg}
                          value={promoCode.input}
                          disabled={promoCode.code !== ""}
                          onChange={(e) =>
                            setPromoCode({
                              ...promoCode,
                              input: e.target.value,
                            })
                          }
                        />
                        <ButtonFuncGApply
                          type="submit"
                          value="apply_promo"
                          disabled={dissub || promoCode.input.length < 5}
                          onClick={(e) => setSmode(e.target.value)}
                        >
                          Apply
                        </ButtonFuncGApply>
                      </div>
                      {(promoCode.code || data.couponCode) && (
                        <div>
                          <CouponMsgBlock>
                            Code #{promoCode.code || data.couponCode} ($
                            {promoCode.discountAmount !== 0 ? promoCode.discountAmount.toFixed(2) : data.discountAmount.toFixed(2)} off) &nbsp;
                            {dyPath !== "/orderonline/allocatedorder" && <ItemRemover
                              title="Remove"
                              onClick={() =>
                                setPromoCode({
                                  code: "",
                                  msg: "Enter Promo Code",
                                  input: "",
                                  discountAmount: 0,
                                  discountValue: 0,
                                  discountItems: [],
                                })
                              }
                            >
                              X
                            </ItemRemover>}
                          </CouponMsgBlock>
                          {dyPath !== "/orderonline/allocatedorder" && <SpanLGeneral xfontSize="12">
                            If you are requiring changes please remove code.
                          </SpanLGeneral>}
                        </div>
                      )}
                    </FlexOrderNWPromo>
                  }
                <H5Title
                  xfontSize="12"
                  xmarginTop={window.innerWidth > 640 ? 5 : 20}
                  xmarginBottom="5"
                >
                  Special Instructions / Further information
                </H5Title>
                <SpInsTextarea
                  id="spita"
                  name="spita"
                  rows="5"
                  placeholder="Please add in any requested batch number for your order or any specific details required."
                  defaultValue={data?.notes}
                  onBlur={(e) => {
                    handleMainKeyChange("notes", e.target.value);
                  }}
                />
                {dyPath !== "/sampleorder/createnew" && (
                  <PGeneral xtextAlign="right" xmarginTop="5">
                    Total Product Cost{" "}
                    {(promoCode.discountValue > 0 || data.discountValue > 0) && (
                      <SpanMGeneral xcolor="noticeRed" xmarginRight="5">
                        <strike>${formatNumberComma(totalCost, 2)} +GST</strike>
                      </SpanMGeneral>
                    )}
                    <SpanBGeneral>
                      $
                      {formatNumberComma(
                        totalCost - (promoCode.discountValue !== 0 ? promoCode.discountValue : data.discountValue || 0),
                        2
                      )}{" "}
                      +GST
                    </SpanBGeneral>
                  </PGeneral>
                )}
                {handlingCost > 0 && (
                  <PGeneral xtextAlign="right" xmarginTop="5">
                    Packing & Handling Fee <SpanBGeneral>$40.0</SpanBGeneral>
                  </PGeneral>
                )}
              </>
            )}
          </FullWidthContainer>
          <FlexButtonGroup>
            {status !== 9 && status !== 1 && status !== 11 && promoCode.code === "" ? (
              <ButtonFuncG onClick={addMoreProducts}>
                {window.innerWidth > 640
                  ? "Add More " + (status >= 100 ? "Samples" : "Products")
                  : "Add More"}
              </ButtonFuncG>
            ) : (
              <span> </span>
            )}
            {mode === "checkout" ? (
              <div>
                {totalCost >= 0 && (
                  <ButtonFuncR
                    type="submit"
                    value="save_draft"
                    xmarginRight={window.innerWidth > 640 ? 10 : 5}
                    disabled={dissub}
                    onClick={(e) => setSmode(e.target.value)}
                  >
                    Save Info
                  </ButtonFuncR>
                )}
                {totalCost >= 0 && status !== 1 && status !== 11 && (
                  <ButtonFuncR
                    xmarginRight={
                      dyPath === "/sampleorder/createnew"
                        ? 0
                        : window.innerWidth > 640
                        ? 15
                        : 5
                    }
                    type="submit"
                    value="place_order"
                    disabled={dissub}
                    onClick={(e) => setSmode(e.target.value)}
                  >
                    {status === 12
                      ? "Activate Order"
                      : status === 100
                      ? "Place Sample Order"
                      : "Place Order"}
                  </ButtonFuncR>
                )}
                {dyPath !== "/sampleorder/createnew" && (
                  <SpanBGeneral xfontSize="14">
                    {window.innerWidth > 640 ? "ESTIMATED TOTAL" : ""}{" "}
                    <SpanBGeneral
                      xbackgroundColor="biege"
                      xpadding={window.innerWidth > 640 ? 10 : 5}
                    >
                      ${" "}
                      <SpanBGeneral xfontSize="18">
                        {formatNumberComma(
                          totalCost + handlingCost - (promoCode.discountValue !== 0 ? promoCode.discountValue : data.discountValue),
                          2
                        )}{" "}
                        +GST
                      </SpanBGeneral>
                    </SpanBGeneral>
                  </SpanBGeneral>
                )}
              </div>
            ) : (
              <div>
                {totalCost > 0 && dyPath === "/orderonline" && (
                  <ButtonFuncR
                    type="submit"
                    value="save_draft"
                    disabled={dissub}
                    onClick={(e) => setSmode(e.target.value)}
                  >
                    Save {getOrderTypes(data?.status || 0)}{" "}
                    {status === 12 || status === 10 ? "" : " Order"}
                  </ButtonFuncR>
                )}
                {totalCost >= 0 &&
                  (dyPath === "/orderonline" ||
                    status === 9 ||
                    status === 100 ||
                    status === 112) && (
                    <ButtonFuncR
                      xmarginLeft="10"
                      type="submit"
                      value="convert_to_order"
                      disabled={dissub}
                      onClick={(e) => setSmode(e.target.value)}
                    >
                      Convert to Order
                    </ButtonFuncR>
                  )}
                {totalCost > 0 &&
                  dyPath === "/temporders/createnew" &&
                  mode !== "edit" && (
                    <ButtonFuncR
                      type="submit"
                      value="temp_hold"
                      disabled={dissub}
                      onClick={(e) => setSmode(e.target.value)}
                    >
                      Confirm Temporary Hold
                    </ButtonFuncR>
                  )}
              </div>
            )}
          </FlexButtonGroup>
        </OrderForm>
      )}
      {!isRender && <LoadingAniC />}
    </TitleCard>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state["draftOrder"],
  };
};

export default connect(mapStateToProps)(DraftOrder);
